<template>
  <div>
    <div class="search-container">
      <div v-if="selectedRowsIds.length > 0">
        <el-dropdown>
          <button class="btn btn-primary" type="button">
            <i class="fa fa-warning"></i> Bulk Actions
          </button>
          <el-dropdown-menu slot="dropdown">
            <a @click="openBulkSendNotification()">
              <el-dropdown-item command="view">
                <i class="fa fa-envelope-o"></i>Send
                Notifications</el-dropdown-item
              >
            </a>
            <a @click="openBulkWASendNotification()">
              <el-dropdown-item command="view">
                <i class="el-icon-chat-dot-round"></i>Send Notifications
                (WA)</el-dropdown-item
              >
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-input
        placeholder="Search"
        size="small"
        v-model="search_string"
        clearable
        prefix-icon="el-icon-search"
        style="width: 300px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          size="mini"
          @click="search"
        ></el-button>
      </el-input>
    </div>
    <el-table
      :data="result_data"
      style="width: 100%"
      class="vue-data-table-default"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :width="250"
        v-for="(step, index) of columns"
        :label="step.label"
        :key="step.key + index"
        :prop="step.key"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row[step.key].name || scope.row[step.key].value }}
        </template>
      </el-table-column>
      <el-table-column
        prop="action"
        label="Actions"
        class="action"
        min-width="170"
        fixed="right"
      >
        <template slot-scope="scope">
          <ul class="action-buttons">
            <li v-for="(action, index) in actions" :key="'action_' + index">
              <button class="btn btn-light btn-sm m-lr-1" type="button">
                <span
                  class="text-muted"
                  title="Add"
                  @click="executeAction(scope.row, action)"
                  ><i class="fa fa-plus-circle"></i
                ></span>
              </button>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <dialog-component
      title="Bulk Notification (selected data)"
      :visible="bulkSendNotificationDialog"
      v-loading="emailConfigLoading"
      element-loading-text="Please wait while we process your request."
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedRowsIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      title="Bulk WA Notification (selected data)"
      :visible="bulkSendNotificationWADialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationWADialog = false"
      :isShowFooter="false"
    >
      <WAConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedRowsIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </WAConfig>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import moment from "moment";

import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import { fetchEntityById } from "@/repo/entityRepo";
export default {
  data() {
    return {
      search_string: "",
      result_data: [],
      selectedRowsIds: [],
      bulkSendNotificationDialog: false,
      bulkSendNotificationWADialog: false,
      emailConfigLoading: false,
      paramsData: {},
      currentEntity: null,
    };
  },
  mixins: [TemplateBuilderhelper, NavigationHelper],
  components: {
    EmailConfig: () => import("@/components/notifications/emailConfig.vue"),
    WAConfig: () => import("@/components/notifications/WAConfig.vue"),
  },
  props: {
    data: Array,
    columns: Array,
    actions: Array,
    getAllEntities: Array,
    getAllCompanyTemplatesData: Object,
    entity_id: String,
    isApplicationUserSide: Boolean
  },
  mounted() {
    this.$store.commit("templates/setSuggestionFormData", null, {
      root: true,
    });
    this.setCurrentEntity();
    this.search();
  },
  computed: {
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("entities", [
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
  },
  methods: {
    async setCurrentEntity() {
      this.currentEntity = await fetchEntityById(this.entity_id);
    },
    async closeBulkSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.bulkSendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending mails initiated",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Sending whatsapp message",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.paramsData = {};
      this.emailConfigLoading = false;
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = false;
    },
    openBulkSendNotification() {
      this.bulkSendNotificationDialog = true;
      this.bulkSendNotificationWADialog = false;
    },
    openBulkWASendNotification() {
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = true;
    },
    handleSelectionChange(checkedDocs) {
      console.log("checkedDocs", checkedDocs);
      this.selectedRowsIds = checkedDocs.map((e) => e._id);
    },
    search() {
      if (this.search_string) {
        this.result_data = [...this.data].filter((row) => {
          return this.columns.find(
            (col) =>
              (row[col.key]?.name &&
                row[col.key].name
                  .toLowerCase()
                  .includes(this.search_string.toLowerCase())) ||
              (row[col.key]?.value &&
                row[col.key].value
                  .toLowerCase()
                  .includes(this.search_string.toLowerCase()))
          )
            ? true
            : false;
        });
      } else {
        this.result_data = [...this.data];
      }
    },
    generateSuggestionForm(entity, otherInfo, row) {
      let form = {};
      (entity?.templates || []).forEach((temp) => {
        let template = (this.getAllCompanyTemplatesData?.data || []).find(
          (e) => e._id == temp.template_id
        );
        if (template?.sections?.[0]?.fields && otherInfo) {
          let tempObj = {};
          template.sections[0].fields.map((field) => {
            let key =
              field && field.key.includes("#")
                ? field.key.split("#")[1]
                : field.key;
            if (field.inputType == "ENTITY") {
              if (otherInfo.entity_id == field.entity_id) {
                tempObj[key] = row._id;
              }
            } else if (field.input_type == "DATE") {
              tempObj[key] = new Date(row.slotDate.value.split(" - ")[0]).toISOString();
              // if (
              //   otherInfo?.other_entity_date &&
              //   otherInfo.other_entity_date.includes("#") &&
              //   otherInfo.other_entity_date.split("#")[1] == key
              // ) {
              //   tempObj[key] = moment(row.slotDate.value.split("-")[0])
              //     .startOf("day")
              //     .toISOString();
              // }
            }
          });
          form[temp.template_id] = tempObj;
        }
      });
      return form;
    },
    executeAction(row, action) {
      let suggestionForm = this.generateSuggestionForm(
          this.getAllEntities.find((e) => action.entity_id == e._id),
          action.other_info,
          row
        );
        this.$store.commit("templates/setSuggestionFormData", suggestionForm, {
          root: true,
        });
      if (action.type == "ADD") {
        this.openEntityDataScreen(action.entity_id, "", "", "fromCustom");
      }else if(action.type == 'FORM_ADD'){
        this.$router.push({
          path: action.url,
          query: {
            fromCustom: true
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  /* width: 100%; */
}
</style>
